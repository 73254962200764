<template>
  <div class="page-success col-between-center">
    <div>
      <!-- <nav-bar ref="head" :headerStyle="{'background': 'transparent'}" :showBack="true" :headerBack="newAppBack" /> -->
        <headBar
            title=""
            left-arrow
            background="transparent"
            @click-left="newAppBack"
            :placeholder="false"
        />
      <img class="banner" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/ren-2.png" alt="">
      <div class="commit col-center-center">
        <img class="icon" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/flag-202201/ren-1.png" alt="">
        <p class="f44 fw6">提交成功</p>
        <p class="p2 f24">感谢您的配合！</p>
      </div>
    </div>
    <p class="des f22">注：如需修改健康资料，请在APP中打开「个人中心」，进入「运动数据」页面，在最底部即可找到入口啦~</p>
  </div>
</template>

<script>
import { newAppBack } from '@/lib/appMethod'
import { getList } from './index'
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
import {swiper, swiperSlide} from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  mixins: [userMixin],
  components: {
    headBar,
    swiper,
    swiperSlide
  },
  data() {
    return {
      dataList: [1,2,3], // 数据列表
      bgList: [1,2,3], // 背景图列表
      swipeOptionBg: {
        effect: 'fade'
      },
      swipeOption: {
        effect: 'cards',
        slidesPerView: 'auto',
        centeredSlides: true,
        // loop: true,
        // initialSlide: 0,
        spaceBetween: 40,
        on:{
          transitionStart: (swiper) => {
            if (this.dataSwiper) {
              this.bgSwiper.slideTo(this.dataSwiper.activeIndex)
            }
          },
        },
      }
    }
  },
  computed: {
    bgSwiper() {
      return this.$refs.bgSwiper.swiper
    },
    dataSwiper() {
      return this.$refs.dataSwiper.swiper
    }
  },
  methods: {
    newAppBack,
    goNext() {
      this.$router.push('page-flag')
    },
  },
  async created() {
    sessionStorage.setItem('startPath',this.$route.path);
    await this.$getAllInfo()
  },
  mounted() {

  },
}
</script>
<style lang="less" scoped>
@import url(./index.less);
.page-success {
  height: 100vh;
  background-color: #F9FAFB;
  padding-bottom: calc(24px + constant(safe-area-inset-top));
  padding-bottom: calc(24px + env(safe-area-inset-top));
  .banner {
    width: 100%;
    margin-bottom: 0;
  }
  .icon {
    width: 308px;
    position: absolute;
    top: -190px;
    left: 202px;
  }
  .commit {
    width: 686px;
    height: 328px;
    background-color: #fff;
    border-radius: 16px;
    margin: -20px auto 0;
    position: relative;
    .p2 {
      color: #6C727A;
      text-align: center;
      margin: 26px 0 0;
    }
  }
  .des {
    color: #9AA1A9;
    padding: 0 32px;
    text-align: center;
  }
}
</style>
